import * as runtimeProcess from 'process';

export const PRODUCTION = 'production';
export const QA = 'qa';
export const DEVELOPMENT = 'development';

// In non-local environments, srv-router will route /api/ to the Mononoth APIs. To avoid this clash, srv-router routes
// /cmspages-api/ to browse-cmspages, which then rewrites the path back to its own /api/ path
export const PUBLIC_API_BASE_PATH = '/cmspages-api/';

export type Environment = 'development' | 'qa' | 'staging' | 'oldstaging' | 'production';

export const getSignInHref = () => {
  const isQa = runtimeProcess.env.ENV_TYPE === QA || process.env.NODE_ENV === DEVELOPMENT;

  return isQa
    ? 'https://www.public.shared.qa.noths.com/u/sign-in'
    : 'https://www.notonthehighstreet.com/u/sign-in';
};
