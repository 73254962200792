/* istanbul ignore file */
import { fetchTopBannerContent } from '@noths/polaris-client-banners';
import type { FeatureFlags } from '@noths/polaris-client-feature-flags';
import { fetchNavigationContent } from '@noths/polaris-client-site-chrome-ui';
import type { Config } from '@noths/polaris-server-content-fetching';
import { Environment, fetchContentByQuery } from '@noths/polaris-server-content-fetching';
import * as runtimeProcess from 'process';

import { DEVELOPMENT, QA } from 'src/constants/environment';
import type { ContentstackSections, ContentstackSeo } from 'src/types/Contentstack';
import type { PageConfiguration, PageMetadata } from 'src/types/PageProps';
import { logger } from 'src/utils/serverLogger';

export const getContentstackEnvironment = (isPreview?: boolean) => {
  if (isPreview) {
    return Environment.Preview;
  }
  const isQa = runtimeProcess.env.ENV_TYPE === QA || process.env.NODE_ENV === DEVELOPMENT;

  return isQa ? Environment.Qa : Environment.Production;
};

interface RibbonsPageContentEntry {
  category_id?: string;
  sections: ContentstackSections[];
  seo: ContentstackSeo;
  title: string;
}

interface GlobalContentOptions {
  featureFlags: FeatureFlags;
  isPreview?: boolean;
  urlPath?: string;
}

export const fetchGlobalContent = async ({
  featureFlags,
  isPreview = false,
  urlPath,
}: GlobalContentOptions) => {
  const env = getContentstackEnvironment(isPreview);
  const config = { env, logger };
  const navContentVariantId = featureFlags.nav_content_experiment;

  return Promise.all([
    fetchNavigationContent(config, navContentVariantId).catch(() => null),
    fetchTopBannerContent(config, urlPath),
  ]);
};

export const fetchPageContentByQuery = async (
  { contentType }: PageConfiguration,
  { isPreview, pathname }: PageMetadata,
) => {
  const config: Config = {
    env: getContentstackEnvironment(isPreview),
    logger,
    stackName: 'Ribbons',
  };

  const data = await fetchContentByQuery(config, contentType, { url: pathname });

  return data.content as RibbonsPageContentEntry;
};
