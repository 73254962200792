import type { PageEvent } from '@noths/polaris-client-next-components';

export const getServerSidePageEventObject = (
  version: string,
  appVersion: string,
  pageEventCategory: string,
): PageEvent => {
  if (!pageEventCategory) {
    return {};
  }

  const pageEventObject = {
    page: {
      category: pageEventCategory,
      environment: process.env.NODE_ENV,
      react: 'true',
      app_version: appVersion,
      release_version: version,
    },
  };

  return pageEventObject;
};
