/* istanbul ignore file */

import { isBrowser } from '@noths/polaris-client-utils';
import bunyan from 'bunyan';
import getConfig from 'next/config';

import type { NextConfig } from 'src/types/NextConfig';

const { serviceName } = (getConfig() as NextConfig).publicRuntimeConfig.monitoring;

export const logger = isBrowser()
  ? (console as unknown as bunyan)
  : bunyan.createLogger({
      name: serviceName,
    });
