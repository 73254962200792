/* istanbul ignore file */
// eslint-disable-next-line simple-import-sort/imports
import 'src/scripts/wdyr';
import React from 'react';
import { setLocaleCookies } from '@noths/polaris-client-localisation';
import { CookieModal } from '@noths/polaris-client-gdpr-compliance';
import { GTMWithDataLayer } from '@noths/polaris-client-google-analytics';
import type { BaseAppProps } from '@noths/polaris-client-next-components';
import { BaseApp } from '@noths/polaris-client-next-components';
import { SkipLinks, useSmartAppBanner } from '@noths/polaris-client-site-chrome-ui';
import type { AppContextPlusCookies } from '@noths/polaris-dev-ts-types';
import type { AppInitialProps, AppProps } from 'next/app';
import NextApp from 'next/app';
import getConfig from 'next/config';
import Head from 'next/head';
import { RECOMMENDATIONS_SCRIPT_URL } from '@noths/polaris-client-recommendations';

import { fetchGlobalContent } from 'src/services/contentstack';
import { SKIP_CONTENT_TARGET_ID } from 'src/constants/elementIds';
import { getFeatureFlagsWithMatchers } from 'src/utils/getFeatureFlagsWithMatchers';
import { isProd } from 'src/environment';
import { getServerSidePageEventObject } from 'src/utils/gaPageEvent';

const config = getConfig();
const AppComponent = BaseApp(config);
const { appVersion, version } = config.publicRuntimeConfig;

type Props = BaseAppProps & Omit<AppProps, 'pageProps'>;

const App = (props: Props) => {
  useSmartAppBanner();
  const pageData = JSON.stringify(
    getServerSidePageEventObject(version, appVersion, props.pageProps?.pageEventCategory),
  );

  return (
    <>
      <Head>
        <CookieModal useTestScript={!isProd()} />
      </Head>
      <GTMWithDataLayer pageEventData={pageData} />
      <script src={RECOMMENDATIONS_SCRIPT_URL} />
      <SkipLinks links={[{ content: 'Skip to content', sectionId: SKIP_CONTENT_TARGET_ID }]} />
      <AppComponent {...props} />
    </>
  );
};

App.getInitialProps = async (
  appContext: AppContextPlusCookies,
): Promise<AppInitialProps & BaseAppProps> => {
  const {
    ctx: { req, res },
    router,
  } = appContext;

  if (req && res) {
    setLocaleCookies({ req, res });
  }

  const featureFlags = (req && getFeatureFlagsWithMatchers(req)) || {};
  const appProps = await NextApp.getInitialProps(appContext);

  if (router.route === '/404') {
    const [navData] = await fetchGlobalContent({ featureFlags });

    return {
      ...appProps,
      navData,
      featureFlags,
    };
  }

  return { ...appProps, featureFlags };
};

export default App;
